const FieldsDebtsClientsArray = [
    {
        key: "selected",
        label: "",
        sortable: false,
        visible: true,
    },
    {
        key: "lead_name",
        label: "Name",
        sortable: true,
        visible: true,
    },
    {
        key: "qty_services",
        label: "Others",
        sortable: false,
        visible: true,
        class: "text-center",
    },
    {
        key: "lead_state",
        label: "State",
        sortable: false,
        visible: true,
    },
    {
        key: "contact_schedule",
        label: "Contact Schedule",
        sortable: false,
        visible: true,
        class: "text-center",
        thStyle: {
            size: "200px",
        },
    },
    {
        key: "account",
        label: "Account",
        sortable: true,
        visible: true,
    },
    {
        key: "available_balance",
        label: "Available Balance",
        sortable: true,
        visible: true,
    },
    {
        key: "total_debt",
        label: "Total Debt",
        visible: true,
    },

    {
        key: "monthly_amount",
        label: "MP",
        sortable: true,
        visible: true,
    },
    {
        key: "last_payment",
        label: "LP",
        sortable: true,
        visible: true,
    },
    {
        key: "status_payment",
        label: "PT",
        sortable: true,
        visible: true,
    },
    {
        key: "state_advisor",
        label: "Advisor",
        visible: true,
    },
    {
        key: "s_note",
        label: "LN",
        sortable: true,
        visible: true,
    },
    {
        key: "created_at",
        label: "Creation Date",
        sortable: true,
        visible: true,
    },
    {
        key: "service",
        label: "Services",
        visible: true,
    },
];


const fieldsDebtsClients = (role_id) => {
    const fields = FieldsDebtsClientsArray.map((x) => ({
        ...x,
        ...validField(x, role_id)
    }));
    return fields.filter(x => x.visible);
}

function validField(field, role_id) {
    const roles = [1, 2, 3];
    switch (field.key) {
        case 'service':
            field.visible = roles.includes(role_id);
            break;
        case 'state_advisor':
            field.visible = role_id != 3;
            break
    }
    return field;
}

export default fieldsDebtsClients;