<template>
  <div>
    <filter-slot
      :filter="customFilter"
      :filter-principal="filterSlot.filterPrincipal"
      :total-rows="filterSlot.totalRows"
      :paginate="filterSlot.paginate"
      :start-page="filterSlot.startPage"
      :to-page="filterSlot.toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="$refs['refClientsList'].refresh()"
      @onSelectChange="changeTypePayment"
    >
      <template #buttons>
        <!-- Button Sms -->
        <!-- <b-button
          variant="success"
          class="ml-1"
          :disabled="lengthClients"
          @click="openModalSendSms"
        >
          <feather-icon icon="MessageCircleIcon" class="mr-50" />Send SMS
        </b-button> -->
        <!-- Button Send Email -->
        <b-button
          variant="success"
          class="ml-1"
          :disabled="lengthClients"
          @click="openModalSendEmail"
        >
          <feather-icon icon="MailIcon" class="mr-50" />Send Email
        </b-button>
        <!-- Button refresh -->
        <b-button
          variant="success"
          class="ml-1"
          :disabled="pnttime"
          @click="contador"
        >
          <feather-icon icon="RefreshCcwIcon" class="mr-50" />{{
            pnttime == false
              ? "Refresh"
              : tiempominutos + ":" + tiemposegundos + " sec"
          }}
        </b-button>
        <div class="ml-3">
          <span class="text-danger mr-2 font-weight-bolder"
            >Red schedule: not available</span
          ><br />
          <span class="text-success mr-2 font-weight-bolder"
            >Green schedule: available</span
          ><br />
          <span class="text-primary font-weight-bolder"
            >Blue schedule: will be available
          </span>
        </div>

        <b-button
          v-if="isAdvisor || isSupervisor"
          variant="info"
          @click="openModalQuestionnaire"
          class="ml-1"
        >
          <span>Questionnaire</span>
          <tabler-icon
            v-if="countQuestionnaire > 0"
            class="ml-50"
            :badge="countQuestionnaire > 99 ? '+99' : countQuestionnaire"
            badge-classes="bg-danger ml-2"
            icon="BookIcon"
          />
          <tabler-icon v-else class="ml-50" icon="BookIcon" />
        </b-button>
      </template>
      <template #custom-vselect>
        <FilterStatusAccount :filters="customFilter[3]" />
      </template>
      <b-table
        slot="table"
        ref="refClientsList"
        small
        no-provider-filtering
        :api-url="apiUrl"
        :items="myProvider"
        :fields="fieldsStatus"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        sticky-header="70vh"
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="filterSlot.paginate.currentPage"
        :per-page="filterSlot.paginate.perPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #head(selected)>
          <b-form-checkbox v-model="selectAll" @input="selectedAll" />
        </template>

        <template v-slot:cell(selected)="data">
          <b-form-group>
            <b-form-checkbox
              v-model="clientsSms"
              :value="{
                id: data.item.account_id,
                name: data.item.lead_name,
              }"
              :name="data.item.account_id"
              class="mt-1"
            />
          </b-form-group>
        </template>

        <!-- Column NAME -->
        <template #cell(lead_name)="data">
          <router-link
            class
            :to="{
              name: 'debt-solution-dashboard',
              params: {
                idClient: data.item.account_id,
              },
            }"
            target="_blank"
            >{{ data.item.lead_name }}
          </router-link>
          <b-badge
            v-if="data.item.new_and_not_welcomed == 1"
            variant="light-warning"
            class="ml-1"
            >W/o Welcome</b-badge
          >
          <br />
          <span v-if="data.item.user_responsible" class="text-warning">
            <tabler-icon
              :icon="
                data.item.user_responsible === currentUser.user_id
                  ? 'StarIcon'
                  : 'LockIcon'
              "
              size="15"
            />
            <strong>CEO's client</strong>
          </span>
          <div>
            <span style="font-weight: 100">{{ data.item.mobile }} </span>
          </div>
          <b-badge v-if="data.item.approved_by_management" variant="info"
            >Approved by Management</b-badge
          >
          <b-badge v-if="data.item.claims != 0" variant="light-info">
            IN CLAIM ({{ data.item.claims }})
          </b-badge>
        </template>

        <!-- column CONTACT SCHEDULE -->
        <template #cell(contact_schedule)="data">
          <div
            v-if="data.item.always_available == 1"
            class="d-flex justify-content-center align-items-center mt-1 font-weight-bolder"
            style="color: #00cc00 !important"
          >
            <feather-icon
              icon="CalendarIcon"
              size="15"
              class="mr-1"
            ></feather-icon>
            Always available
          </div>
          <div v-else-if="data.item.contact_schedule">
            <div
              class="text-left"
              style="font-weight: 100"
              v-for="(schedule, index) in JSON.parse(
                data.item.contact_schedule
              ).slice(0, 3)"
              :key="index"
            >
              <b-row :class="getColor(schedule)">
                <b-col cols="1">
                  <feather-icon
                    v-if="[1, 2].includes(schedule.priority)"
                    icon="AlertTriangleIcon"
                    size="12"
                    style="margin-left: 5px"
                    v-b-tooltip.hover
                    :title="
                      { 1: 'High Priority', 2: 'Medium Priority' }[
                        schedule.priority
                      ]
                    "
                    :class="{
                      'text-danger': schedule.priority == 1,
                      'text-warning': schedule.priority == 2,
                    }"
                  ></feather-icon>
                </b-col>
                <b-col cols="4">
                  <feather-icon icon="CalendarIcon" size="12"></feather-icon>
                  <small>
                    {{ schedule.date }}
                  </small>
                </b-col>
                <b-col cols="6">
                  <feather-icon
                    icon="ClockIcon"
                    size="12"
                    style="margin-right: 3px"
                  ></feather-icon>
                  <small for="" v-if="schedule.time_to">
                    {{ schedule.time_from | myTime }} -
                    {{ schedule.time_to | myTime }}</small
                  >
                  <small for="" v-else
                    >From: {{ schedule.time_from | myTime }}</small
                  >
                </b-col>
              </b-row>
              <hr
                v-if="index + 1 < JSON.parse(data.item.contact_schedule).length"
                style="margin: 0px !important"
              />
            </div>
            <b-button
              v-if="JSON.parse(data.item.contact_schedule).length > 3"
              variant="info"
              size="sm"
              @click="showFullContactSchedule(data.item.contact_schedule)"
            >
              +
              {{
                JSON.parse(data.item.contact_schedule).length > 3
                  ? JSON.parse(data.item.contact_schedule).length - 3
                  : ""
              }}
            </b-button>
          </div>
        </template>

        <!-- Column ACCOUNT -->
        <template #cell(account)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li style="list-style: none">
                <span style="font-weight: 100">{{ data.item.account }}</span>
              </li>
            </ul>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li style="list-style: none">
                <status-account :account="data.item"> </status-account>
              </li>
            </ul>
          </div>
        </template>

        <!-- Column AVAILABLE BALANCE -->
        <template
          v-if="currentUser.role_id != 4"
          #cell(available_balance)="data"
        >
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                style="list-style: none"
                :class="
                  data.item.total_balance > 0 ? 'text-primary' : 'text-danger'
                "
              >
                $ {{ data.item.total_balance | currencyZero }}
              </li>
            </ul>
          </div>
        </template>

        <!-- Column Total Debt -->
        <template v-if="currentUser.role_id != 4" #cell(total_debt)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li style="list-style: none">
                {{ data.item.total_accounts | myMoney }}
              </li>
            </ul>
          </div>
        </template>

        <!-- Column MP -->
        <template v-if="currentUser.role_id != 4" #cell(monthly_amount)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li style="list-style: none">
                {{ data.item.monthly_amount | myMoney }}
              </li>
            </ul>
          </div>
        </template>

        <!-- Column LP -->
        <template v-if="currentUser.role_id != 4" #cell(last_payment)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li style="list-style: none; position: relative">
                <span style="margin-left: 15px">
                  {{
                    data.item.last_payment == "-" ? "" : data.item.last_payment
                  }}
                  <img
                    alt=""
                    :src="assetsImg + '/images/icons/lastpayment-out.ico'"
                    v-if="data.item.state_lp == 1"
                    style="
                      position: absolute;
                      left: 0;
                      top: 50%;
                      transform: translate(0, -50%);
                    "
                  />

                  <img
                    alt=""
                    :src="assetsImg + '/images/icons/lastpayment-in.ico'"
                    v-else
                    style="
                      position: absolute;
                      left: 0;
                      top: 50%;
                      transform: translate(0, -50%);
                    "
                  />
                </span>
              </li>
            </ul>
          </div>
        </template>

        <!-- Column PT -->
        <template v-if="currentUser.role_id != 4" #cell(status_payment)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li style="list-style: none">
                <status-payment :account="data.item" />
              </li>
            </ul>
          </div>
        </template>

        <!-- Column ADVISOR -->
        <template v-if="currentUser.role_id != 3" #cell(state_advisor)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li style="list-style: none">
                <div>
                  <feather-icon
                    id="icon"
                    :title="
                      data.item.state_advisor == 1
                        ? 'Active'
                        : data.item.state_advisor == 2
                        ? 'Busy'
                        : data.item.state_advisor == 3
                        ? 'Away'
                        : 'Offline'
                    "
                    icon="CircleIcon"
                    size="13"
                    :style="
                      data.item.state_advisor == 1
                        ? 'background:green'
                        : data.item.state_advisor == 2
                        ? 'background:orange'
                        : data.item.state_advisor == 3
                        ? 'background:red'
                        : 'background:#ccc'
                    "
                  />
                  {{ data.item.advisor_name }}
                </div>
              </li>
            </ul>
          </div>
        </template>

        <!-- Column LN -->
        <template v-if="currentUser.role_id != 4" #cell(s_note)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px; list-style: none">
              <li
                class="li-created-at-account"
                :class="
                  data.item.s_note <= 1
                    ? ''
                    : data.item.s_note <= 2
                    ? 'text-warning'
                    : 'text-danger'
                "
              >
                {{ data.item.last_note }}
              </li>
            </ul>
          </div>
        </template>

        <!-- Column CREATION DATE -->
        <template #cell(created_at)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li style="list-style: none" class="li-created-at-account">
                {{ data.item.created_at | myGlobal }}
              </li>
            </ul>
          </div>
        </template>

        <!-- Column SERVICES -->
        <template #cell(service)="{ item }">
          <b-dropdown
            v-if="
              item.user_responsible == currentUser.user_id ||
              item.user_responsible == null
            "
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item variant="warning" @click="modalAddService(item)">
              <feather-icon icon="FilePlusIcon" />
              <span class="align-middle ml-50">Add service</span>
            </b-dropdown-item>

            <!-- Change Service -->
            <b-dropdown-item
              variant="success"
              @click="modalChangeService(item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Change service</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!-- COLUMN OTHER PROGRAMS -->
        <template #cell(qty_services)="{ item }">
          <ClientPrograms
            :clientData="item"
            :programName="item.program"
            :programId="item.program_id"
          />
        </template>
      </b-table>
    </filter-slot>

    <!-- Modal send sms -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="clientsSms[0]"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Send Email -->
    <modal-send-email
      v-if="modalSendEmail"
      :modalSendEmail="modalSendEmail"
      :nameLeads="clientsSms"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      :original-event-id="event"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />

    <!--modal view contact schedule-->
    <b-modal
      v-model="showModalSchedule"
      title="Full Contact schedule"
      title-tag="h3"
      modal-class="modal-primary"
      title-class="h3 text-white font-weight-bolder"
      scrollable
      size="default"
    >
      <b-card no-body class="mb-0">
        <div
          v-for="(date, index) in contactScheduleList"
          :key="index"
          class="list-group-item bg-transparent rounded-0"
          :class="isDarkSkin ? 'border-light' : ''"
        >
          <b-row
            :class="
              date.date == currentDate.day && date.time_to < currentDate.hour
                ? 'past'
                : (date.date == customFilter[7].model ||
                    date.date == currentDate.day) &&
                  ((date.time_from <= customFilter[8].model &&
                    date.time_to >= customFilter[9].model) ||
                    (date.time_from <= currentDate.hour &&
                      date.time_to >= currentDate.hour) ||
                    !date.time_to)
                ? 'now'
                : date.date == currentDate.day
                ? 'future'
                : ''
            "
          >
            <b-col cols="1">
              <feather-icon
                v-if="date.priority != 3"
                icon="AlertTriangleIcon"
                size="17"
                v-b-tooltip.hover
                :title="
                  date.priority == 1 ? 'High Priority' : 'Medium Priority'
                "
                class="ml-1"
                :class="date.priority == 1 ? 'text-danger' : 'text-warning'"
              ></feather-icon>
            </b-col>
            <b-col cols="6">
              <span for="" class="">
                <feather-icon icon="CalendarIcon"></feather-icon>
                {{ date.date }}
              </span>
            </b-col>
            <b-col cols="5">
              <feather-icon icon="ClockIcon"></feather-icon>
              <span v-if="date.time_to">
                {{ date.time_from | myTime }} -
                {{ date.time_to | myTime }}
              </span>
              <span v-else> From {{ date.time_from | myTime }} </span>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="(showModalSchedule = false), (contactScheduleList = [])"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <QuestionnaireModal v-if="G_S_VALIDATE_OLD_QUESTIONNAIRE" />
  </div>
</template>
<script>
import fieldsDebtsClients from "@/views/debt-solution/views/clients/data/clients.fields";
import { mapActions, mapGetters, mapState } from "vuex";
import DebtSolutionServices from "@/views/debt-solution/services/debt-solution.service.js";
import clientsFiltersDebts from "@/views/debt-solution/views/clients/data/clients.filters";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import ClientDebtsService from "@/views/debt-solution/views/clients/services/clientdebts.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import QuestionnaireModal from "@/views/commons/components/modals-container/ModalsContainer.vue";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import ModalAddService from "@/views/commons/components/clients/modals/ModalAddService.vue";
import moment from "moment";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
import ClientPrograms from "@/views/commons/components/client-programs/ClientPrograms.vue";
import Vue from "vue";

export default {
  name: "ClientsGrid",
  components: {
    StatusAccount,
    StatusPayment,
    ModalSendEmail,
    ModalSendSms,
    QuestionnaireModal,
    ModalAddService,
    NcrLeadsService,
    FilterStatusAccount,
    ClientPrograms,
  },
  data() {
    return {
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      isAddUpdateUserSidebarActive: false,
      editSidebar: "first",
      typeEdit: "lead",
      customFilter: [],
      typeAddChange: null,
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      filterSlot: {
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Client...",
          model: "",
        },
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 50,
        },
        startPage: null,
        toPage: null,
        nextPage: "",
        endPage: "",
      },
      modal: {
        programs: false,
      },
      totalAmount: 0,
      sortBy: "",
      sortDesc: true,
      modalOpenAddService: false,
      typeModal: null,
      clients: null,
      clientsSms: [],
      selectAll: 0, // total
      allClients: [], // lista de listas
      arrayCurrentPage: [],
      isBusy: false,
      tiemposegundos: 30,
      tiempominutos: 0,
      time1: true,
      pnttime: false,
      advisor: "",
      modalSendSms: false,
      modalSendEmail: false,
      actionsOptions: [],
      detailsClient: {},
      saleClientP: {},
      programsAll: [],
      usersServices: [],
      event: null,
      showModalSchedule: false,
      contactScheduleList: [],
      countQuestionnaire: 0,
    };
  },
  computed: {
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
    }),

    ...mapGetters({
      currentUser: "auth/currentUser",
      G_EEUU_STATES: "CrmGlobalStore/G_EEUU_STATES",
      G_S_VALIDATE_OLD_QUESTIONNAIRE:
        "modal-actions/G_S_VALIDATE_OLD_QUESTIONNAIRE",
    }),
    fieldsStatus() {
      return fieldsDebtsClients(this.currentUser.role_id);
    },
    lengthClients() {
      return this.clientsSms.length == 0;
    },
    isAdvisor() {
      return this.currentUser.role_id == 3;
    },
    apiUrl() {
      return "/clients/clientsds";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    currentDate() {
      return {
        day: moment().format("dddd").toUpperCase(),
        hour: moment().format("HH:mm:ss"),
      };
    },
  },
  watch: {},
  async created() {
    this.customFilter = clientsFiltersDebts;
    await this.usersPrograms();
    await this.usersAllPrograms();
    if (this.isAdvisor) {
      await this.getCountNotificationQuestionnaire();
    }
    this.getAllStatusClientAccount();
  },
  methods: {
    ...mapActions({
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      getOldQuestionnaire: "modal-actions/A_VALIDATE_OLD_QUESTIONNAIRE",
    }),
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    getColor({ date, time_from, time_to }) {
      const filterDate = this.customFilter[7].model
        ? this.customFilter[7].model
        : this.currentDate.day;
      const filterTimeFrom = this.customFilter[8].model
        ? this.customFilter[8].model
        : this.currentDate.hour;
      const filterTimeTo = this.customFilter[9].model
        ? this.customFilter[9].model
        : this.currentDate.hour;

      return date == filterDate && time_to < filterTimeTo
        ? "past"
        : date == filterDate &&
          time_from <= filterTimeFrom &&
          (time_to >= filterTimeTo || !time_to)
        ? "now"
        : date == filterDate
        ? "future"
        : "";
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    changeTypePayment(type) {
      if (type == "paymentType") {
        if (this.customFilter[4].model == 1) {
          this.customFilter[5].visible = true;
        } else {
          this.customFilter[5].model = null;
          this.customFilter[5].visible = false;
        }
      }
    },
    async myProvider(ctx) {
      const sortOptions = {
        lead_name: 2,
        account: 5,
        available_balance: 50,
        monthly_amount: 43,
        last_payment: 44,
        status_payment: 48,
        s_note: 45,
        created_at: 5,
      };
      let sortBy = sortOptions[ctx.sortBy];
      let sortDirection = ctx.sortDesc ? "desc" : "asc";
      try {
        let module = 4;
        this.advisor = this.customFilter[2].model;
        let params = {};
        params["modo"] = "test";
        params["page"] = ctx.currentPage;
        params["program"] = module;
        params["advisor"] = this.advisor;
        params["perPage"] = this.filterSlot.paginate.perPage;
        params["text"] = this.filterSlot.filterPrincipal.model;
        params["from"] = this.customFilter[0].model;
        params["to"] = this.customFilter[1].model;
        params["orderby"] = sortBy;
        params["order"] = sortDirection;
        params["status"] = this.customFilter[3].model;
        params["type"] = this.customFilter[4].model;
        params["day"] = this.customFilter[5]?.model;
        params["state"] = this.customFilter[6]?.model;
        params["rol_id"] = this.currentUser.role_id;
        params["schedule_date_from"] = this.customFilter[7]?.model;
        params["schedule_time_from"] = this.customFilter[8]?.model;
        params["schedule_time_to"] = this.customFilter[9]?.model;

        const data = await ClientDebtsService.searchds(params);
        const items = data.data;
        this.clients = items;
        this.filterSlot.startPage = data.from || 0;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        this.filterSlot.nextPage = this.startPage + 1;
        this.filterSlot.endPage = data.last_page || 0;
        this.filterSlot.totalRows = data.total || 0;
        this.filterSlot.toPage = data.to || 0;
        return items || [];
      } catch (ex) {
        console.log(ex);
      }

      return [];
    },
    async getCountNotificationQuestionnaire() {
      try {
        const { data } =
          await DebtSolutionServices.notificationOldQquestionnaire({
            advisor_id: this.currentUser.user_id,
          });
        this.countQuestionnaire = data.length;
      } catch (error) {
        console.log(error);
      }
    },
    async usersPrograms() {
      try {
        const params = {
          idmodule: this.moduleId,
          iduser: this.currentUser.user_id,
          idrole: 1,
        };
        const data = await ClientService.usersPrograms(params);
        this.customFilter[2].label = "Advisor";
        this.customFilter[2].selectText = "user_name";
        this.customFilter[2].options = data;
      } catch (error) {
        console.log(error);
      }
    },
    usersAllPrograms: async function () {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });
        this.usersServices = data.data; //.filter(item => item.id != 1);
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },
    selectedAll() {
      if (this.selectAll == 1) {
        let nameCl = [];
        this.clients.map((dato) => {
          nameCl.push({
            id: JSON.parse(dato.accounts)[0].account_id,
            name: dato.lead_name,
          });
        });
        let value = this.allClients.find(
          (element) =>
            element.currentPage == this.filterSlot.paginate.currentPage
        );
        if (!value) {
          this.allClients.push({
            currentPage: this.filterSlot.paginate.currentPage,
            array: nameCl,
          });
        }
      }
      if (this.selectAll == 0) {
        const value = this.allClients.find(
          (element) =>
            element.currentPage == this.filterSlot.paginate.currentPage
        );
        if (value) {
          const index = this.allClients.indexOf(value);
          this.allClients.splice(index, 1);
        }
      }
      this.clientsSms = [];
      for (const client of this.allClients) {
        this.clientsSms = [...this.clientsSms, ...client.array];
      }
    },
    async contador() {
      try {
        this.pnttime = true;
        this.updateReloj();
        await ClientService.generateRefresh();
      } catch (error) {
        console.log(error);
      }
    },
    updateReloj() {
      const ts = this.tiemposegundos;
      const tm = this.tiempominutos;
      this.tiemposegundos = ts;
      this.tiempominutos = tm;
      if (this.tiempominutos == 0 && this.tiemposegundos == 0) {
        this.$refs.refClientsList.refresh();
        this.pnttime = false;
        this.tiemposegundos = 59;
        this.tiempominutos = 1;
      } else {
        // Restamos un segundo al tiempo restante
        if (this.tiemposegundos == 0) {
          this.tiemposegundos = 59;
          if (this.tiempominutos != 0) {
            this.tiempominutos -= 1;
          }
        }
        this.tiemposegundos -= 1;
        // Ejecutamos nuevamente la función al pasar 1000 milisegundos (1 segundo)
        setTimeout(() => {
          this.updateReloj();
        }, 1000);
      }
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    openModalSendSms() {
      this.modalSendSms = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    async openModalQuestionnaire() {
      this.getOldQuestionnaire({
        advisor_id: !this.isSupervisor ? this.currentUser.user_id : null,
      });
    },
    // Create the event
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    modalChangeService: async function (data) {
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        this.typeAddChange = 2;
        await this.createEvent(data);
        this.modalGeneralService(data, 4);
      }
    },
    modalAddService: async function (data) {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );

      if (result.value) {
        this.typeAddChange = 1;
        await this.createEvent(data);
        this.modalGeneralService(data, 3);
      }
    },
    modalGeneralService(data, type) {
      this.detailsClient = data;
      Vue.set(this.detailsClient, "event_id", this.event)
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    async openModalProgram(data) {
      const param = {
        prices: null,
        observation: "Service",
        contract: 1,
        program: data.program.id,
        fee: null,
        suggested: null,
        otherpricesp: null,
        event: this.event,
        json_noce: null,
        stateid: 0,

        // Diferents to add change Services
        account: this.detailsClient.account_id,
        captured: data.captured.id,
        seller: data.seller.id,
        type: this.typeAddChange,
        user_id: this.currentUser.user_id,
        module: this.moduleId,
        id_score: null,
        json_ce: null,
        type_client: "CLIENT",
      };
      try {
        let result = await LeadService.insertSaleMade(param);
        if (result.status == 200) {
          this.modalOpenAddService = false;
          this.$router
            .push({ name: "sales-made-debtsolution-client-pending" })
            .catch((err) => {
              console.log(err);
            });
        }
      } catch (error) {
        this.showErrorSwal(
          "Please, verify if the client already has the service, otherwise contact support.",
          "Error"
        );
      } finally {
        this.removePreloader();
      }
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      }

      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    showFullContactSchedule(contactSchedule) {
      this.contactScheduleList = JSON.parse(contactSchedule);
      this.showModalSchedule = true;
    },
    async getAllStatusClientAccount() {
      this.customFilter[3].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.customFilter[3].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.customFilter[3].options.push(...data);
    },
  },
  async mounted() {
    await this.getEeuuStates();
    this.customFilter[6].options = this.G_EEUU_STATES;
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.now {
  color: #00cc00 !important;
  font-weight: bold !important;
  animation: grow_up 0.8s ease-in-out 3;
}
.past {
  color: #fc424a !important;
  font-weight: bold !important;
}
.future {
  color: #0090e7 !important;
  font-weight: bold !important;
}
.now small,
.past small,
.future small {
  font-weight: bold !important;
  color: currentColor;
}
@keyframes grow_up {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
