const clientsFiltersDebts = [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Advisor",
    model: null,
    options: [],
    reduce: "id",
    selectText: "user_name",
    cols: 12,
  },
  {
    type: "custom-select",
    margin: true,
    showLabel: true,
    label: "Client Status",
    model: null,
    options: [],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Payment Type",
    model: null,
    options: [
      { value: 0, label: "All" },
      { value: 1, label: "Automatic" },
      { value: 2, label: "Manual" },
      { value: 3, label: "Others" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
    emitEvent: true,
    typeEvent: "paymentType",
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Day Payment",
    model: null,
    options: [
      { value: 0, label: "All" },
      { value: 5, label: "5" },
      { value: 10, label: "10" },
      { value: 15, label: "15" },
      { value: 20, label: "20" },
      { value: 25, label: "25" },
      { value: 30, label: "30" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: false,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "State",
    model: null,
    options: [],
    reduce: "value",
    selectText: "state",
    cols: 12,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Day schedule",
    model: null,
    options: [
      {
        value: 1,
        text: "MONDAY",
      },
      {
        value: 2,
        text: "TUESDAY",
      },
      {
        value: 3,
        text: "WEDNESDAY",
      },
      {
        value: 4,
        text: "THURSDAY",
      },
      {
        value: 5,
        text: "FRIDAY",
      },
      {
        value: 6,
        text: "SATURDAY",
      },
      {
        value: 7,
        text: "SUNDAY",
      },
    ],
    reduce: "text",
    selectText: "text",
    cols: 12,
    visible: true,
  },
  {
    type: "timer",
    margin: true,
    showLabel: true,
    label: "Hour From",
    placeholder: "Hour From",
    class: "font-small-3",
    model: null,
    cols: 6,
  },
  {
    type: "timer",
    margin: true,
    showLabel: true,
    label: "Hour to",
    placeholder: "Hour To",
    class: "font-small-3",
    model: null,
    cols: 6,
  },
];

export default clientsFiltersDebts;
